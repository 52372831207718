import { Button, Col, Drawer, Form, Input, Row, Select, Spin, Switch, Tooltip } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { CanAccess } from 'o2o_layout';
import { Organizations, TypeConsoleUser, TypeOrganization } from 'o2o_layout/lib/common/model';
import { User } from 'o2o_layout/lib/common/model/user';
import React from "react";
import { CheckPolicyPasswordInput } from './profile/CheckPolicyPasswordInput';
import { O2OFormItem } from '../../shares/O2OFormItem';


export interface ModalConsleUserProps extends FormComponentProps {
    visible?: boolean,
    onCreate?: any,
    loading?: boolean,
    onCancel?: any
    user?: TypeConsoleUser,
    orgs: Organizations,
    onSearchOrg: any,
    currentUser: User,
    isSAdmin: boolean,
    commonActionFromDAL: any
    roles: undefined | string[]
}

export const DrawerConsoleUserAdmin = Form.create<ModalConsleUserProps>({ name: 'form_in_modal' })(
    // eslint-disable-next-line
    class extends React.Component<ModalConsleUserProps, any> {

        state = {
            confirmDirty: false,
            autoCompleteResult: [],
            errors: []
        };

        getOrgCtx = (user: User | undefined, property: string) => {
            if (user && user.orgCtx && user.orgCtx.organization) {
                return user.orgCtx.organization[property]
            }
            return 'N/A';
        }


        compareToFirstPassword = (rule: any, value: any, callback: any) => {
            const form = this.props.form;
            if (value && value !== form.getFieldValue('password')) {
                callback('Two passwords that you enter is inconsistent!');
            } else {
                callback();
            }
        };
        handleConfirmBlur = (e: any) => {
            const value = e.target.value;
            this.setState({ confirmDirty: this.state.confirmDirty || !!value });
        };
        validateToNextPassword = (rule: any, value: any, callback: any) => {
            const form = this.props.form;
            if (value && this.state.confirmDirty) {
                form.validateFields(['confirm'], { force: true });
            }
            callback();
        };

        renderRoles = (isSAdmin: boolean, getFieldDecorator: any, user: TypeConsoleUser | undefined) => {

            return (
                <O2OFormItem help="" label="Roles">
                    {getFieldDecorator('roles',
                        {
                            initialValue: this.props.roles,
                            rules: [{
                                required: true,
                                message: 'Please choose roles!'
                            }],

                        })(
                            <Select mode="multiple" style={{ width: '100%' }} placeholder="Roles">
                                <Select.Option key="API">Api</Select.Option>
                                <Select.Option key="USER">User</Select.Option>
                                <Select.Option key="ADMIN">Admin</Select.Option>
                            </Select>
                        )}
                </O2OFormItem>
            )


        }

        renderChooseOrg = (isSAdmin: boolean, getFieldDecorator: any, user: TypeConsoleUser | undefined, onSearchOrg: any, loading: boolean | undefined, orgs: Organizations, currentUser: User) => {

            if (isSAdmin && user == undefined) {

                return <O2OFormItem label="Organizations">
                    {getFieldDecorator('orgId',
                        {
                            // initialValue: user != undefined ? user.orgIds : [],
                            rules: [{
                                required: true,
                            }],

                        })(
                            <Select
                                showSearch

                                style={{ width: '100%' }}
                                placeholder="Organizations"
                                onSearch={onSearchOrg}
                                filterOption={false}
                                notFoundContent={loading ? <Spin size="small" /> : null}
                            >
                                {
                                    orgs.results.content.map((item: TypeOrganization) => {
                                        return (
                                            <Select.Option key={item.id}>{item.name}</Select.Option>
                                        )
                                    })
                                }
                            </Select>
                        )}
                </O2OFormItem>

            } else if (!isSAdmin) {
                return <CanAccess permission={['ADMIN', 'USER']} userRoles={currentUser ? currentUser.orgCtx ? currentUser.orgCtx.roles : [] : []}>
                    <O2OFormItem label="Organization">
                        {
                            <Input disabled={true} value={this.getOrgCtx(currentUser, 'name')} />
                        }
                    </O2OFormItem>
                </CanAccess>
            } else {
                return null
            }

        }

        render() {
            const { visible, form, loading, user, onSearchOrg, orgs, isSAdmin, currentUser, onCancel, onCreate } = this.props;
            const { getFieldDecorator } = form;
            const userStatus = user && user.status == 'active' ? true : false
            return (
                <Drawer
                    width={450}
                    title={user && user.id ? "UPDATE ACCOUNT" : "ADD ACCOUNT"}
                    visible={visible}
                    onClose={onCancel}
                    bodyStyle={{
                        paddingBottom: 80
                    }}
                    maskClosable={false}

                >

                    <Spin spinning={loading}>


                        <Form layout="vertical" autoComplete="false" hideRequiredMark={true} colon={false}>

                            {user && user.id ? (
                                <O2OFormItem label="Api key" style={{ display: "none" }}>
                                    {getFieldDecorator('apiKey', {
                                        initialValue: user && user.apiKey ? user.apiKey : '',

                                    })(<Input disabled={user && user.id ? true : false} />)}
                                </O2OFormItem>
                            ) : null}
                            <Row gutter={[5, 5]} type="flex" justify="space-between">

                                <Col xs={24}>
                                    <O2OFormItem help="" label="Name">
                                        {getFieldDecorator('name', {
                                            initialValue: user && user.name ? user.name : '',
                                            rules: [
                                                {
                                                    required: true,
                                                }
                                            ]
                                        })(<Input placeholder="Name" disabled={user && user.name ? true : false} />)}
                                    </O2OFormItem>

                                </Col>
                                <Col xs={24}>
                                    <O2OFormItem help="" label={<span>
                                        Email
                                        <Tooltip title="User will use this email to login into PangoCDP. Require a valid email.">
                                            <Button type='link' size='small' icon='question-circle' />
                                        </Tooltip>
                                    </span>}>
                                        {getFieldDecorator('email', {
                                            initialValue: user && user.id ? user.id : '',
                                            rules: [
                                                {
                                                    type: 'email',
                                                    message: 'The input is not valid E-mail!',
                                                },
                                                {
                                                    required: true,
                                                }
                                            ]
                                        })(<Input autoComplete="false" placeholder="Email" disabled={user && user.id ? true : false} />)}
                                    </O2OFormItem>
                                </Col>


                                {
                                    user && user.id ? null : (
                                        <Col xs={24}>
                                            <CheckPolicyPasswordInput
                                                commonActionFromDAL={this.props.commonActionFromDAL}
                                                getFieldDecorator={this.props.form.getFieldDecorator}
                                                form={this.props.form}
                                                errors={(v: string[]) => {
                                                    this.setState({
                                                        errors: v
                                                    })
                                                }}

                                            />
                                        </Col>
                                    )
                                }

                                {
                                    user && user.id ? null : (
                                        <Col xs={24}>
                                            <O2OFormItem help="" label="Confirm Password">
                                                {getFieldDecorator('confirm', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                        },
                                                        {
                                                            validator: this.compareToFirstPassword,
                                                        },
                                                    ],
                                                })(<Input.Password placeholder="**************" onBlur={this.handleConfirmBlur} />)}
                                            </O2OFormItem>
                                        </Col>
                                    )
                                }

                                <Col xs={24}>
                                    {this.renderRoles(isSAdmin, getFieldDecorator, user)}
                                </Col>

                                {user && user.id ? null : (
                                    <>
                                        <Col xs={24}>
                                            <O2OFormItem help="" label="Phone">
                                                {getFieldDecorator('phone', {
                                                    initialValue: user && user.phone ? user.phone : '',
                                                })(<Input autoComplete="false" placeholder="Phone" />)}
                                            </O2OFormItem>
                                        </Col>
                                        <Col xs={24}>
                                            <O2OFormItem label="Address">
                                                {getFieldDecorator('address', {
                                                    initialValue: user && user.address ? user.address : ''
                                                })(<Input autoComplete="false" placeholder="Address" />)}
                                            </O2OFormItem>
                                        </Col>
                                        <Col xs={24}>
                                            {this.renderChooseOrg(isSAdmin, getFieldDecorator, user, onSearchOrg, loading, orgs, currentUser)}
                                        </Col>
                                    </>
                                )}

                                {
                                    user && user.id ? (
                                        <Col xs={24}>
                                            <O2OFormItem label="Status">
                                                {getFieldDecorator('active', {
                                                    initialValue: userStatus,
                                                    valuePropName: 'checked',
                                                })(<Switch
                                                    unCheckedChildren={"Inactive"}
                                                    checkedChildren={"Active"} disabled={user.orgIds && user.orgIds.length > 1 ? true : false}></Switch>)}
                                            </O2OFormItem>
                                        </Col>
                                    ) : null
                                }


                            </Row>
                        </Form>

                    </Spin>

                    <div
                        style={{
                            position: 'absolute',
                            right: 0,
                            bottom: 0,
                            width: '100%',
                            borderTop: '1px solid #e9e9e9',
                            padding: '10px 16px',
                            background: '#fff',
                            textAlign: 'right',
                        }}
                    >
                        <Button style={{ marginRight: 8 }} onClick={onCancel}>Close</Button>

                        <Button disabled={this.state.errors.length ? true : false} type="primary" onClick={onCreate} loading={loading}>
                            {user ? "Update" : "Create"}
                        </Button>


                    </div>

                </Drawer>
            );
        }
    },
);
