import { Form, Input, Alert, Col, Row, Tooltip } from 'antd';
import React from "react";
import { useAsyncRetry, useDebounce } from 'react-use';
import { ConsoleUsersDAL } from '../ConsoleUserDAL';
import _ from 'lodash';
import { PolicyPasswordCheck } from './ChangePassword';
import { O2OFormItem } from '../../../shares/O2OFormItem';



interface Props {
    commonActionFromDAL: any
    getFieldDecorator: any
    form: any
    errors: any
    visible?: boolean
}


export const CheckPolicyPasswordInput = (props: Props) => {


    const [val, setVal] = React.useState(undefined as undefined | string);
    const [q, setQ] = React.useState(undefined as undefined | string);

    const statePolicyPasswword = useAsyncRetry(async () => {
        if (q) {
            const data = await loadPolicyPassword();
            props.errors(data.result.errors)
            return data
        }
        return new PolicyPasswordCheck()
    }, [q])

    const loadPolicyPassword = async () => {
        const result: Promise<PolicyPasswordCheck> = props.commonActionFromDAL(ConsoleUsersDAL, "checkPasswordPolicy", q)
        try {
            const payload: PolicyPasswordCheck = await result;
            return payload
        } catch (error) {
            return new PolicyPasswordCheck()
        }
    }

    const [,] = useDebounce(
        () => {
            if (val != undefined) {
                setQ(val)
            }
        },
        500,
        [val]
    );

    const renderError = () => {
        if (statePolicyPasswword.value && statePolicyPasswword.value.success) {
            if (statePolicyPasswword.value.result.errors && statePolicyPasswword.value.result.errors.length) {
                return (
                    <Form.Item>
                        <Alert
                            message=""
                            description={
                                <ul>
                                    {statePolicyPasswword.value.result.errors.map((item: string) => <li>{item}</li>)}
                                </ul>
                            }
                            type="error"
                        />
                    </Form.Item>
                )
            }
            return null
        }
        return null
    }

    const renderLevel = () => {
        if (statePolicyPasswword.value && statePolicyPasswword.value.success) {
            if (statePolicyPasswword.value.result.level == "Low") {
                return (
                    <Row gutter={[5, 5]} style={{ marginTop: 5 }}>
                        <Col xs={6}>
                            <Tooltip title="Low">
                                <div style={{
                                    background: "#FF445E",
                                    width: "100%",
                                    height: 8,
                                    borderRadius: 10
                                }}></div>
                            </Tooltip>

                        </Col>
                        <Col xs={6}>
                            <Tooltip title="Fair">
                                <div style={{
                                    background: "#d9d9d9",
                                    width: "100%",
                                    height: 8,
                                    borderRadius: 10
                                }}></div>
                            </Tooltip>
                        </Col>
                        <Col xs={6}>
                            <Tooltip title="Good">
                                <div style={{
                                    background: "#d9d9d9",
                                    width: "100%",
                                    height: 8,
                                    borderRadius: 10
                                }}></div>
                            </Tooltip>
                        </Col>
                        <Col xs={6}>
                            <Tooltip title="Strong">
                                <div style={{
                                    background: "#d9d9d9",
                                    width: "100%",
                                    height: 8,
                                    borderRadius: 10
                                }}></div>
                            </Tooltip>
                        </Col>

                    </Row>

                )
            } else if (statePolicyPasswword.value.result.level == "Fair") {
                return (
                    <Row gutter={[5, 5]} style={{ marginTop: 5 }}>
                        <Col xs={6}>
                            <Tooltip title="Low">
                                <div style={{
                                    background: "#FF445E",
                                    width: "100%",
                                    height: 8,
                                    borderRadius: 10
                                }}></div>
                            </Tooltip>

                        </Col>
                        <Col xs={6}>
                            <Tooltip title="Fair">
                                <div style={{
                                    background: "rgb(255 166 44)",
                                    width: "100%",
                                    height: 8,
                                    borderRadius: 10
                                }}></div>
                            </Tooltip>
                        </Col>
                        <Col xs={6}>
                            <Tooltip title="Good">
                                <div style={{
                                    background: "#d9d9d9",
                                    width: "100%",
                                    height: 8,
                                    borderRadius: 10
                                }}></div>
                            </Tooltip>
                        </Col>
                        <Col xs={6}>
                            <Tooltip title="Strong">
                                <div style={{
                                    background: "#d9d9d9",
                                    width: "100%",
                                    height: 8,
                                    borderRadius: 10
                                }}></div>
                            </Tooltip>
                        </Col>

                    </Row>

                )
            } else if (statePolicyPasswword.value.result.level == "Good") {
                return (
                    <Row gutter={[5, 5]} style={{ marginTop: 5 }}>
                        <Col xs={6}>
                            <Tooltip title="Low">
                                <div style={{
                                    background: "#FF445E",
                                    width: "100%",
                                    height: 8,
                                    borderRadius: 10
                                }}></div>
                            </Tooltip>

                        </Col>
                        <Col xs={6}>
                            <Tooltip title="Fair">
                                <div style={{
                                    background: "rgb(255 166 44)",
                                    width: "100%",
                                    height: 8,
                                    borderRadius: 10
                                }}></div>
                            </Tooltip>
                        </Col>
                        <Col xs={6}>
                            <Tooltip title="Good">
                                <div style={{
                                    background: "rgb(45, 183, 245)",
                                    width: "100%",
                                    height: 8,
                                    borderRadius: 10
                                }}></div>
                            </Tooltip>
                        </Col>
                        <Col xs={6}>
                            <Tooltip title="Strong">
                                <div style={{
                                    background: "#d9d9d9",
                                    width: "100%",
                                    height: 8,
                                    borderRadius: 10
                                }}></div>
                            </Tooltip>
                        </Col>

                    </Row>

                )
            } else if (statePolicyPasswword.value.result.level == "Strong") {
                return (
                    <Row gutter={[5, 5]} style={{ marginTop: 5 }}>
                        <Col xs={6}>
                            <Tooltip title="Low">
                                <div style={{
                                    background: "#FF445E",
                                    width: "100%",
                                    height: 8,
                                    borderRadius: 10
                                }}></div>
                            </Tooltip>

                        </Col>
                        <Col xs={6}>
                            <Tooltip title="Fair">
                                <div style={{
                                    background: "rgb(255 166 44)",
                                    width: "100%",
                                    height: 8,
                                    borderRadius: 10
                                }}></div>
                            </Tooltip>
                        </Col>
                        <Col xs={6}>
                            <Tooltip title="Good">
                                <div style={{
                                    background: "rgb(45, 183, 245)",
                                    width: "100%",
                                    height: 8,
                                    borderRadius: 10
                                }}></div>
                            </Tooltip>
                        </Col>
                        <Col xs={6}>
                            <Tooltip title="Strong">
                                <div style={{
                                    background: "rgb(135, 208, 104)",
                                    width: "100%",
                                    height: 8,
                                    borderRadius: 10
                                }}></div>
                            </Tooltip>
                        </Col>

                    </Row>

                )
            }
        }
        return null
    }

    const policyP = statePolicyPasswword && statePolicyPasswword.value ? statePolicyPasswword.value : new PolicyPasswordCheck();

    React.useEffect(() => {
        if (!props.visible) {
            setVal(undefined)
            setQ(undefined)
        }
    }, [props.visible])

    const renderTheme = () => {

        const { getFieldDecorator } = props

        let valid = (policyP.result.errors.length ? true : false)

        console.log(valid)
        return (
            <React.Fragment>

                <O2OFormItem help="" label="Password">
                    {getFieldDecorator('password', {
                        rules: [
                            { required: true, message: "Please enter password" },
                        ],
                    })(<Input.Password autoComplete="new-password" placeholder="**************" onChange={(e: any) => {
                        setVal(e.currentTarget.value)
                    }} />)}
                    {renderLevel()}
                </O2OFormItem>


                {renderError()}

            </React.Fragment>
        )
    }
    return (
        renderTheme()
    );
}
