import {
  Button,
  Col,
  Drawer,
  Form,
  Icon,
  Input,
  Row,
  Select,
  notification,
} from "antd";
import { FormComponentProps } from "antd/lib/form";
import React from "react";
import { O2OFormItem } from "../../../../shares/O2OFormItem";
import { TypePermistion } from "../../permisions/model/permissions";
import _ from "lodash";
import { SAPermission } from "../dal/DAL";
import { BIGDATA_CONSTANT } from "../../../../common/constant";
import { getPrimaryColor } from "../../../../common/helper";
import { useSelector } from "../../../../redux/store";
import { IState } from "../../../../redux/reducer";

interface Iprops extends FormComponentProps {
  visible: boolean;
  onClose: () => void;
  item?: TypePermistion;
  commonActionFromDALV2: any;
  onSuccess: () => void;
}
const DrawerAddUpdateComponent = Form.create<Iprops>()((props: Iprops) => {
  const { visible, onClose, onSuccess } = props;
  const { getFieldDecorator, validateFields, resetFields } = props.form;

  const [loading, setLoading] = React.useState(false as boolean);
  const uiSetting = useSelector((state: IState) => state.uiSetting);

  const onSave = () => {
    validateFields(async (err: any, values: any) => {
      if (err) return;
      setLoading(true);

      let payload = {
        id: values.id,
        name: values.name,
        categoryId: values.categoryId,
        description: values.description,
      };
      if (values.categoryId === "ACCOUNT") {
        payload = {
          ...payload,
          ...{ category: "Account" },
        };
      } else if (values.categoryId === "MODULE_Workflow") {
        payload = {
          ...payload,
          ...{ category: "Workflow" },
        };
      } else if (values.categoryId === "MODULE_Segment") {
        payload = {
          ...payload,
          ...{ category: "Segmentation" },
        };
      } else if (values.categoryId === "MODULE_Dataset") {
        payload = {
          ...payload,
          ...{ category: "Dataset" },
        };
      } else if (values.categoryId === "MODULE_ExternalOAuth2") {
        payload = {
          ...payload,
          ...{ category: "External OAuth2" },
        };
      } else if (values.categoryId === "ZMA") {
        payload = {
          ...payload,
          ...{ category: "Zalo Mini App" },
        };
      } else if (values.categoryId === "SYSTEM") {
        payload = {
          ...payload,
          ...{ category: "System" },
        };
      } else if (values.categoryId === "MODULE") {
        payload = {
          ...payload,
          ...{ category: "Module" },
        };
      } else {
        payload = {
          ...payload,
          ...{ category: "DataHub" },
        };
      }

      try {
        let result;
        if (props.item) {
          result = props.commonActionFromDALV2(
            SAPermission.update,
            props.item.id,
            payload
          );
        } else {
          result = props.commonActionFromDALV2(SAPermission.add, payload);
        }
        const response = await result;
        if (response.success) {
          onClose();
          resetFields();
          onSuccess();
          setLoading(false);
          notification.success({
            message: BIGDATA_CONSTANT.message.default.title.success,
            description: props.item
              ? BIGDATA_CONSTANT.message.notification.success.update
              : BIGDATA_CONSTANT.message.notification.success.add,
            icon: (
              <Icon
                type="check-circle"
                style={{ color: getPrimaryColor(uiSetting) }}
              />
            ),
          });
        } else {
          notification.error({
            message: BIGDATA_CONSTANT.message.default.title.error,
            description: _.get(response, "message", "Something went wrong"),
          });
          setLoading(false);
        }
      } catch (error) {
        notification.error({
          message: BIGDATA_CONSTANT.message.default.title.error,
          description: _.get(error, "message", "Something went wrong"),
        });
        setLoading(false);
      }
    });
  };

  React.useEffect(() => {
    if (!props.visible) {
      resetFields();
    }
  }, [props.visible]);
  return (
    <Drawer
      title={
        <>
          <Button
            icon="left-circle"
            size="small"
            type="link"
            onClick={() => {
              onClose();
            }}
          ></Button>
          {props.item ? "UPDATE PERMISSIONS" : "ADD PERMISSIONS"}
        </>
      }
      width={500}
      onClose={onClose}
      visible={visible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Form layout="vertical" colon={false} hideRequiredMark={true}>
        <Row gutter={[5, 5]} type="flex">
          <Col xs={24}>
            <O2OFormItem label="Key">
              {getFieldDecorator("id", {
                initialValue: _.get(props.item, "id", undefined),
                rules: [
                  {
                    required: true,
                    message: "Required",
                  },
                ],
              })(
                <Input
                  placeholder="Enter"
                  readOnly={props.item && props.item.id ? true : false}
                />
              )}
            </O2OFormItem>
          </Col>
          <Col xs={24}>
            <O2OFormItem label="Name">
              {getFieldDecorator("name", {
                initialValue: _.get(props.item, "name", undefined),
                rules: [
                  {
                    required: true,
                    message: "Required",
                  },
                ],
              })(<Input placeholder="Enter" />)}
            </O2OFormItem>
          </Col>
          <Col xs={24}>
            <O2OFormItem label="Category">
              {getFieldDecorator("categoryId", {
                initialValue:
                  _.get(props.item, "categoryId", undefined) || undefined,
                rules: [
                  {
                    required: true,
                    message: "Required",
                  },
                ],
              })(
                <Select placeholder="Select one">
                  <Select.Option value={"ACCOUNT"}>Account</Select.Option>
                  <Select.Option value={"MODULE_Workflow"}>
                    Workflow
                  </Select.Option>
                  <Select.Option value={"MODULE_Segment"}>
                    Segmentation
                  </Select.Option>
                  <Select.Option value={"MODULE_Dataset"}>
                    Dataset
                  </Select.Option>
                  <Select.Option value={"MODULE_ExternalOAuth2"}>
                    External OAuth2
                  </Select.Option>
                  <Select.Option value={"DataHub"}>DataHub</Select.Option>
                  <Select.Option value={"SYSTEM"}>System</Select.Option>
                  <Select.Option value={"MODULE"}>Module</Select.Option>
                  <Select.Option value={"ZMA"}>Zalo Mini App</Select.Option>
                </Select>
              )}
            </O2OFormItem>
          </Col>
          <Col xs={24}>
            <O2OFormItem label="Description">
              {getFieldDecorator("description", {
                initialValue: _.get(props.item, "description", undefined),
              })(<Input placeholder="Enter" />)}
            </O2OFormItem>
          </Col>
        </Row>
        <div
          style={{
            position: "absolute",
            right: 0,
            bottom: 0,
            width: "100%",
            borderTop: "1px solid #e9e9e9",
            padding: "10px 16px",
            background: "#fff",
            textAlign: "right",
          }}
        >
          <Button onClick={onClose} style={{ marginRight: 8 }}>
            Cancel
          </Button>
          <Button onClick={onSave} type="primary" loading={loading}>
            {props.item ? "Update" : "Add"}
          </Button>
        </div>
      </Form>
    </Drawer>
  );
});

export default DrawerAddUpdateComponent;
