import { Methods, RestClient } from "o2o_layout/lib/common/rest";
import { BIGDATA_CONSTANT } from "../../../../common/constant";

class DAL {
    saveThemeColor(color: string) {
        let url = BIGDATA_CONSTANT.url.api + 'mngmnt-client/orgs/config/gui/save';
        var restClient = new RestClient(Methods.POST, url);
        return new Promise((resolve, reject) => {
            restClient.execute({}, {
                "theme": "",
                "themeColor": color
            }).then((response: any) => {
                resolve(response.data)
            }, (error: any) => {
                reject(error);
            });
        });
    }

    getThemeColor() {
        let url = BIGDATA_CONSTANT.url.api + 'mngmnt-client/orgs/config/gui/get';
        var restClient = new RestClient(Methods.GET, url);
        return new Promise((resolve, reject) => {
            restClient.execute({}, {}).then((response: any) => {
                resolve(response.data)
            }, (error: any) => {
                reject(error);
            });
        });
    }

    saveConfigData(data: any) {

        let url = BIGDATA_CONSTANT.url.api + 'mngmnt-client/orgs/config/data/save';
        var restClient = new RestClient(Methods.POST, url);
        return new Promise((resolve, reject) => {
            restClient.execute({}, data).then((response: any) => {
                resolve(response.data)
            }, (error: any) => {
                reject(error);
            });
        });
    }

    getConfigData() {

        let url = BIGDATA_CONSTANT.url.api + 'mngmnt-client/orgs/config/data/get';
        var restClient = new RestClient(Methods.GET, url);
        return new Promise((resolve, reject) => {
            restClient.execute({}, {}).then((response: any) => {
                resolve(response.data)
            }, (error: any) => {
                reject(error);
            });
        });
    }

    saveConfigPersonalize(data: any) {
        let url = BIGDATA_CONSTANT.url.api + 'mngmnt-client/orgs/config/personalize/update';
        var restClient = new RestClient(Methods.POST, url);
        return new Promise((resolve, reject) => {
            restClient.execute({}, data).then((response: any) => {
                resolve(response.data)
            }, (error: any) => {
                reject(error);
            });
        });
    }

    getConfigPersonalize() {
        let url = BIGDATA_CONSTANT.url.api + 'mngmnt-client/orgs/config/personalize/get';
        var restClient = new RestClient(Methods.GET, url);
        return new Promise((resolve, reject) => {
            restClient.execute({}, {}).then((response: any) => {
                resolve(response.data)
            }, (error: any) => {
                reject(error);
            });
        });
    }

    updateLogoOrg(file: File) {
        let url = BIGDATA_CONSTANT.url.api + `mngmnt-client/orgs/update/_logo`;
        var restClient = new RestClient(Methods.POST, url);
        const formData = new FormData();
        formData.append('file', file)
        var headers = {
            'Content-Type': 'multipart/form-data'
        };
        return new Promise((resolve, reject) => {
            restClient.execute(headers, formData).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }

}

export const CompanyDAL = new DAL();
