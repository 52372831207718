import { Form, Input, Select, Row, Col, Spin, Switch, Drawer, Button } from 'antd';
import React from "react";
import { FormComponentProps } from 'antd/lib/form';
import { TypeConsoleUser, Organizations, TypeOrganization } from 'o2o_layout/lib/common/model';
import { User } from 'o2o_layout/lib/common/model/user';
import { CanAccess } from 'o2o_layout';
import { CheckPolicyPasswordInput } from './profile/CheckPolicyPasswordInput';


export interface ModalConsleUserProps extends FormComponentProps {
    visible?: boolean,
    onCreate?: any,
    loading?: boolean,
    onCancel?: any
    user?: TypeConsoleUser,
    orgs: Organizations,
    onSearchOrg: any,
    currentUser: User,
    isSAdmin: boolean,
    commonActionFromDAL: any
    roles: undefined | string[]
}

export const ModalConsoleUser = Form.create<ModalConsleUserProps>({ name: 'form_in_modal' })(
    // eslint-disable-next-line
    class extends React.Component<ModalConsleUserProps, any> {

        state = {
            confirmDirty: false,
            autoCompleteResult: [],
            errors: []
        };

        getOrgCtx = (user: User | undefined, property: string) => {
            if (user && user.orgCtx && user.orgCtx.organization) {
                return user.orgCtx.organization[property]
            }
            return 'N/A';
        }


        compareToFirstPassword = (rule: any, value: any, callback: any) => {
            const form = this.props.form;
            if (value && value !== form.getFieldValue('password')) {
                callback('Two passwords that you enter is inconsistent!');
            } else {
                callback();
            }
        };
        handleConfirmBlur = (e: any) => {
            const value = e.target.value;
            this.setState({ confirmDirty: this.state.confirmDirty || !!value });
        };
        validateToNextPassword = (rule: any, value: any, callback: any) => {
            const form = this.props.form;
            if (value && this.state.confirmDirty) {
                form.validateFields(['confirm'], { force: true });
            }
            callback();
        };

        renderRoles = (isSAdmin: boolean, getFieldDecorator: any, user: TypeConsoleUser | undefined) => {
            if (isSAdmin) {

                return (null
                    //     <Form.Item label="Roles">
                    //     {getFieldDecorator('roles',
                    //         {
                    //             initialValue: user && user.orgCtx ? user.orgCtx.roles : [],
                    //             rules: [{
                    //                 required: true,
                    //                 message: 'Please choose roles!'
                    //             }],

                    //         })(
                    //             <Select mode="multiple" style={{ width: '100%' }} placeholder="Roles">
                    //                 <Select.Option key="ADMIN">Admin</Select.Option>
                    //                 <Select.Option key="API">Api</Select.Option>
                    //                 <Select.Option key="USER">User</Select.Option>
                    //             </Select>
                    //         )}
                    // </Form.Item>
                )
            } else {
                return (
                    <Form.Item label="Roles">
                        {getFieldDecorator('roles',
                            {
                                initialValue: this.props.roles,
                                rules: [{
                                    required: true,
                                    message: 'Please choose roles!'
                                }],

                            })(
                                <Select mode="multiple" style={{ width: '100%' }} placeholder="Roles">
                                    <Select.Option key="ADMIN">Admin</Select.Option>
                                    <Select.Option key="API">Api</Select.Option>
                                    <Select.Option key="USER">User</Select.Option>
                                    <Select.Option key="AdminOperator">Admin Operator</Select.Option>
                                </Select>
                            )}
                    </Form.Item>
                )
            }

        }

        renderChooseOrg = (isSAdmin: boolean, getFieldDecorator: any, user: TypeConsoleUser | undefined, onSearchOrg: any, loading: boolean | undefined, orgs: Organizations, currentUser: User) => {

            if (isSAdmin && user == undefined) {

                return <Form.Item help="" label="Oranizations" style={{ marginBottom: 0 }}>
                    {getFieldDecorator('orgId',
                        {
                            // initialValue: user != undefined ? user.orgIds : [],
                            rules: [{
                                required: true,
                            }],

                        })(
                            <Select
                                showSearch

                                style={{ width: '100%' }}
                                placeholder="Organizations"
                                onSearch={onSearchOrg}
                                filterOption={false}
                                notFoundContent={loading ? <Spin size="small" /> : null}
                            >
                                {
                                    orgs.results.content.map((item: TypeOrganization) => {
                                        return (
                                            <Select.Option key={item.id}>{item.name}</Select.Option>
                                        )
                                    })
                                }
                            </Select>
                        )}
                </Form.Item>

            } else if (!isSAdmin) {
                return <CanAccess permission={['ADMIN', 'USER']} userRoles={currentUser ? currentUser.orgCtx ? currentUser.orgCtx.roles : [] : []}>
                    <Form.Item label="Oranization">
                        {
                            <Input disabled={true} value={this.getOrgCtx(currentUser, 'name')} />
                        }
                    </Form.Item>
                </CanAccess>
            } else {
                return null
            }

        }

        render() {
            const { visible, onCancel, onCreate, form, loading, user, onSearchOrg, orgs, isSAdmin, currentUser } = this.props;
            const { getFieldDecorator } = form;
            const userStatus = user && user.status == 'active' ? true : false
            return (
                <Drawer
                    title={user && user.id ? "Update User" : "Create User"}
                    visible={visible}
                    maskClosable={false}
                    onClose={onCancel}
                    width={500}
                    bodyStyle={{ marginBottom: 60 }}
                >
                    <Form layout="vertical" autoComplete="false" hideRequiredMark={true} colon={false}>

                        {user && user.id ? (
                            <Form.Item label="Api key" style={{ display: "none" }}>
                                {getFieldDecorator('apiKey', {
                                    initialValue: user && user.apiKey ? user.apiKey : '',

                                })(<Input disabled={user && user.id ? true : false} />)}
                            </Form.Item>
                        ) : null}
                        <Row gutter={[5, 5]} type="flex" justify="space-between">
                            <Col xs={24}>
                                <Form.Item help="" label="Email" style={{ marginBottom: 0 }}>
                                    {getFieldDecorator('email', {
                                        initialValue: user && user.id ? user.id : '',
                                        rules: [
                                            {
                                                type: 'email',
                                                message: 'The input is not valid E-mail!',
                                            },
                                            {
                                                required: true,
                                            }
                                        ]
                                    })(<Input autoComplete="false" placeholder="email" disabled={user && user.id ? true : false} />)}
                                </Form.Item>

                            </Col>

                            <Col xs={24} >
                                <Form.Item help="" label="Full name" style={{ marginBottom: 0 }}>
                                    {getFieldDecorator('name', {
                                        initialValue: user && user.name ? user.name : '',
                                        rules: [
                                            {
                                                required: true,
                                            }
                                        ]
                                    })(<Input placeholder="name" />)}
                                </Form.Item>

                            </Col>

                            <Col xs={24}>
                                <Form.Item label="Phone" style={{ marginBottom: 0 }}>
                                    {getFieldDecorator('phone', {
                                        initialValue: user && user.phone ? user.phone : ''
                                    })(<Input autoComplete="false" placeholder="phone" />)}
                                </Form.Item>
                            </Col>
                            <Col xs={24}>
                                <Form.Item label="Address" style={{ marginBottom: 0 }}>
                                    {getFieldDecorator('address', {
                                        initialValue: user && user.address ? user.address : ''
                                    })(<Input autoComplete="false" placeholder="address" />)}
                                </Form.Item>
                            </Col>

                            <Col xs={24}>
                                {this.renderChooseOrg(isSAdmin, getFieldDecorator, user, onSearchOrg, loading, orgs, currentUser)}
                            </Col>
                            {
                                user && user.id ? null : (
                                    <Col xs={24}>
                                        <CheckPolicyPasswordInput
                                            commonActionFromDAL={this.props.commonActionFromDAL}
                                            getFieldDecorator={this.props.form.getFieldDecorator}
                                            form={this.props.form}
                                            errors={(v: string[]) => {
                                                this.setState({
                                                    errors: v
                                                })
                                            }}
                                            visible={visible}
                                        />
                                    </Col>
                                )
                            }

                            {
                                user && user.id ? null : (
                                    <Col xs={24}>
                                        <Form.Item help="" label="Confirm Password">
                                            {getFieldDecorator('confirm', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please confirm your password!',

                                                    },
                                                    {
                                                        validator: this.compareToFirstPassword,
                                                    },
                                                ],
                                            })(<Input.Password placeholder="*********" onBlur={this.handleConfirmBlur} />)}
                                        </Form.Item>
                                    </Col>
                                )
                            }

                            {
                                this.renderRoles(isSAdmin, getFieldDecorator, user)
                            }

                            {
                                user && user.id ? (
                                    <Col xs={24}>
                                        <Form.Item label="Active">
                                            {getFieldDecorator('active', {
                                                initialValue: userStatus,
                                                valuePropName: 'checked',
                                            })(
                                                <Switch unCheckedChildren={"Inactive"} checkedChildren={"Active"} />
                                            )}
                                        </Form.Item>
                                    </Col>
                                ) : null
                            }
                        </Row>

                    </Form>
                    <div
                        style={{
                            position: 'absolute',
                            right: 0,
                            bottom: 0,
                            width: '100%',
                            borderTop: '1px solid #e9e9e9',
                            padding: '10px 16px',
                            background: '#fff',
                            textAlign: 'right',
                        }}
                    >
                        <Button type="default" style={{ marginRight: 10 }} onClick={onCancel}>Cancel</Button>
                        <Button loading={loading} type="primary" onClick={onCreate} disabled={this.state.errors.length ? true : false}>{user && user.id ? "Update" : "Create"} </Button>
                    </div>
                </Drawer>
            );
        }
    },
);
