
import { Methods, RestClient } from 'o2o_layout/lib/common/rest';
import { BIGDATA_CONSTANT } from '../../../common/constant';

class DAL {


    constructor() {

    }



    getPersonalize() {
        let url = BIGDATA_CONSTANT.url.console + `mngmnt-client/orgs/config/personalize/get`;
        var restClient = new RestClient(Methods.GET, url);
        return new Promise((resolve, reject) => {
            restClient.execute({}, {

            }).then((response: any) => {
                resolve(response.data)
            }, (error: any) => {
                reject(error);
            });
        });
    }

    getSegmentConfig() {
        let url = BIGDATA_CONSTANT.url.console + `mngmnt-client/orgs/config/segment/get`;
        var restClient = new RestClient(Methods.GET, url);
        return new Promise((resolve, reject) => {
            restClient.execute({}, {

            }).then((response: any) => {
                resolve(response.data)
            }, (error: any) => {
                reject(error);
            });
        });
    }

    updatePersonalize(dto: any) {
        let url = BIGDATA_CONSTANT.url.console + `mngmnt-client/orgs/config/personalize/update`;
        var restClient = new RestClient(Methods.POST, url);
        return new Promise((resolve, reject) => {
            restClient.execute({}, dto).then((response: any) => {
                resolve(response.data)
            }, (error: any) => {
                reject(error);
            });
        });
    }

    updateSegmentExport(dto: any) {
        let url = BIGDATA_CONSTANT.url.console + `mngmnt-client/orgs/config/segment/export/update`;
        var restClient = new RestClient(Methods.POST, url);
        return new Promise((resolve, reject) => {
            restClient.execute({}, dto).then((response: any) => {
                resolve(response.data)
            }, (error: any) => {
                reject(error);
            });
        });
    }


    getCountries() {
        let url = BIGDATA_CONSTANT.url.console + `utilities/metadata/countries`;
        var restClient = new RestClient(Methods.GET, url);
        return new Promise((resolve, reject) => {
            restClient.execute({}, {}).then((response: any) => {
                resolve(response.data)
            }, (error: any) => {
                reject(error);
            });
        });
    }

    getDataExportConfig() {
        let url = BIGDATA_CONSTANT.url.console + `mngmnt-client/orgs/config/export/get`;
        var restClient = new RestClient(Methods.GET, url);
        return new Promise((resolve, reject) => {
            restClient.execute({}, {}).then((response: any) => {
                resolve(response.data)
            }, (error: any) => {
                reject(error);
            });
        });
    }
    updateDataExportConfig(dto: any) {
        let url = BIGDATA_CONSTANT.url.console + `mngmnt-client/orgs/config/export/update`;
        var restClient = new RestClient(Methods.POST, url);
        return new Promise((resolve, reject) => {
            restClient.execute({}, dto).then((response: any) => {
                resolve(response.data)
            }, (error: any) => {
                reject(error);
            });
        });
    }
    updateQuotaLimit(orgId: string, category: string, dto: any) {
        let url = BIGDATA_CONSTANT.url.console + `mngmnt/orgs/config/quota/${orgId}/${category}/update`;
        var restClient = new RestClient(Methods.POST, url);
        return new Promise((resolve, reject) => {
            restClient.execute({}, dto).then((response: any) => {
                resolve(response.data)
            }, (error: any) => {
                reject(error);
            });
        });
    }
    getQuotaSupperAdmin(orgId: string, category: string) {
        let url = BIGDATA_CONSTANT.url.console + `mngmnt/orgs/config/quota/${orgId}/${category}/get`;
        var restClient = new RestClient(Methods.GET, url);
        return new Promise((resolve, reject) => {
            restClient.execute({}, {}).then((response: any) => {
                resolve(response.data)
            }, (error: any) => {
                reject(error);
            });
        });
    }
    getQuotaLimit(category: string) {
        let url = BIGDATA_CONSTANT.url.console + `mngmnt-client/orgs/config/quota/${category}/get`;
        var restClient = new RestClient(Methods.GET, url);
        return new Promise((resolve, reject) => {
            restClient.execute({}, {}).then((response: any) => {
                resolve(response.data)
            }, (error: any) => {
                reject(error);
            });
        });
    }
    getSubCategory() {
        let url = BIGDATA_CONSTANT.url.console + `task/metadata/sub-category`;
        var restClient = new RestClient(Methods.GET, url);
        return new Promise((resolve, reject) => {
            restClient.execute({}, {}).then((response: any) => {
                resolve(response.data)
            }, (error: any) => {
                reject(error);
            });
        });
    }

    suggestionOrg(query: string) {
        let url = BIGDATA_CONSTANT.url.api + `mngmnt/orgs/suggest?query=${query}`;
        var restClient = new RestClient(Methods.GET, url);
        return new Promise((resolve, reject) => {
            restClient.execute({}, {}).then(
                (response: any) => {
                    const user: any = response.data;
                    resolve(user);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }

}

export const SettingDAL = new DAL()
