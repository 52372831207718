import React from 'react'
import { Form, notification, Icon, Row, Col, Spin, Select, Collapse, Tooltip, InputNumber, Input, Divider, Popconfirm, Button, Typography, Empty, Switch } from 'antd'
import { useSelector, useDispatch } from '../../../redux/store'
import { IState } from '../../../redux/reducer'
import { SHOW_COMMON_ERROR, IActionUI } from '../../../redux/reducer/uiReducer'
import { getPrimaryColor } from '../../../common/helper'
import _, { get } from 'lodash'
import { O2OFormItem } from '../../../shares/O2OFormItem'
import moment from 'moment'
import { Methods } from 'o2o_layout/lib/common/rest'
import { FormComponentProps } from 'antd/lib/form'
import { SettingDAL } from './SettingDAL'
import { WrappedFormUtils } from 'antd/lib/form/Form'
import { BIGDATA_CONSTANT } from '../../../common/constant'
import update from 'react-addons-update'
import { CommonDAL } from '../../../shares/dal/CommonDAL'

interface Iprops extends FormComponentProps {
    commonActionFromDALV2: any
    loading: (value: boolean) => void
    id?: string
}

type TypeQuotaSetting = {
    alertMessage: string,
    category: string,
    enabledBlocked: boolean,
    id: string,
    maxAllowed: number,
    orgId: string,
    quotaByKeys: any | TypeQuotaByKeysAPIIngestion,
    unblockedPeriodInMin: number,
    updatedAt: number,
    updatedBy: string
}

type TypeQuotaByKeysAPIIngestion = {
    _5minutely: number,
    _hourly: number,
    _daily: number
}

type TypeSubItemForm = {
    data: string
    lasItemLength: number
    indexColumn: number
    subData: string[]
    form: WrappedFormUtils<any>
    onAdd: any
    onDelete: any
    onChange?: any
}

const TYPE_UPDATE = {
    UPDATE_API_INGESTION: "UPDATE_API_INGESTION",
    UPDATE_TASK: "UPDATE_TASK",
}

export type TypeBlockObject = {
    orgId: any,
    quotaLevel: any,
    quotaUsed: number,
    quotaUsedAtBlocked: number,
    unblockAfterMins: number,
    unblockedAt: number,
    quotaKey: any
}


class TagQuotaSetting {
    success: boolean
    results: string[]
    constructor() {
        this.success = false
        this.results = []
    }
}

const SubCategogyItemForm = (props: TypeSubItemForm) => {
    const [data, setData] = React.useState<string[]>([])

    const onChangeSelect = (value: string) => {
        props.onChange({
            sub: value,
            number: props.form.getFieldValue(`number[${props.indexColumn}]`)
        })
    }
    const onChangeInputNumber = (value: string) => {
        props.onChange({
            number: value,
            sub: props.form.getFieldValue(`sub[${props.indexColumn}]`)
        })
    }

    React.useEffect(() => {
        if (props.data) {
            const splitData = props.data.split("=")
            setData(splitData)
        }
    }, [props.data])

    return (
        <Row gutter={[10, 10]} type="flex">
            <Col xs={10} md={10} sm={10} xl={10} xxl={6}>
                <O2OFormItem >
                    {props.form.getFieldDecorator(`sub[${props.indexColumn}]`, {
                        rules: [{ required: true, message: "Required" }],
                        initialValue: data && data.length === 2 && data[0] !== "" ? data[0] : undefined
                    })(
                        <Select
                            allowClear
                            showArrow={true}
                            placeholder='Select a model'
                            optionFilterProp='children'
                            showSearch
                            filterOption={(input: any, option: any) => {
                                return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                            }}
                            onChange={(value: string) => {
                                onChangeSelect(value)
                            }}
                        >
                            {props.subData && props.subData.map((item: string) => (
                                <Select.Option key={item} value={item}>{item}</Select.Option>
                            ))}
                        </Select>
                    )}
                </O2OFormItem>
            </Col>
            <Col>
                <O2OFormItem>
                    {props.form.getFieldDecorator(`number[${props.indexColumn}]`, {
                        rules: [
                            {
                                validator(rule, value, callback, source, options) {
                                    if (value > props.form.getFieldValue("maxAllowed")) {
                                        return Promise.reject("Value should be less than or equal Max Tasks");
                                    } else if (value < 1) {
                                        return Promise.reject("Value should be more than 1");
                                    } else if (value === undefined) {
                                        return Promise.reject("Required");
                                    } else {
                                        return Promise.reject();
                                    }
                                },
                            }
                        ],
                        initialValue: data && data.length === 2 && data[1] !== "" ? +data[1] : undefined
                    })(
                        <Input
                            type="number"
                            placeholder='Please enter number'
                            style={{ width: "100%" }}
                            onChange={(e: any) => {
                                onChangeInputNumber(e.target.value)
                            }}
                        >
                        </Input>
                    )}
                </O2OFormItem>
            </Col>

            <Col>
                <O2OFormItem>
                    <Row gutter={[5, 5]} type="flex" align='middle'>
                        <Col>
                            <Popconfirm
                                title='Are you sure?'
                                onConfirm={() => {
                                    props.onDelete();
                                }}>
                                <Button
                                    icon='delete'
                                    type='link'
                                    size='small'
                                />
                            </Popconfirm>
                        </Col>

                        <Col>
                            {props.indexColumn === props.lasItemLength - 1 ? (
                                <Button
                                    icon='plus-circle'
                                    size='small'
                                    type='link'
                                    onClick={() => {
                                        props.onAdd();
                                    }}
                                />
                            ) : null}
                        </Col>
                    </Row>
                </O2OFormItem>
            </Col>
        </Row>
    )
}

export const QuotaSetting = Form.create<Iprops>()((props: Iprops) => {
    const [loadingAPIIngestion, setLoadingAPIIngestion] = React.useState(false)
    const [loadingTask, setLoadingTask] = React.useState(false)
    const [data, setData] = React.useState(undefined as undefined | TypeQuotaSetting)
    const [blockObject, setBlocObject] = React.useState(undefined as undefined | TypeBlockObject)
    const [data2, setData2] = React.useState(undefined as undefined | TypeQuotaSetting)
    const [subData, setSubData] = React.useState([])
    const [dataColumn, setDataColumn] = React.useState([] as any | string[])
    const [isDeleteItem, setIsDeleteItem] = React.useState(false);
    const [query, setQuery] = React.useState("")
    const [res, setRes] = React.useState(new TagQuotaSetting())
    const [loading, setLoading] = React.useState(false)

    const uiSetting = useSelector((state: IState) => state.uiSetting)
    const dispatch = useDispatch()

    const loadDataAPIIngestion = async () => {
        props.loading(true)
        const result = await props.commonActionFromDALV2(SettingDAL.getQuotaSupperAdmin, props.form.getFieldValue("organization"), "ApiIngestion")
        try {
            const payload = await result
            if (payload.success) {
                loadDataTask()
                setData(payload.result)
                setBlocObject(payload.blockObject)
            } else {
                notification.error({
                    message: BIGDATA_CONSTANT.message.default.title.error,
                    description: get(payload, "message", BIGDATA_CONSTANT.message.notification.fail)
                })
            }
            props.loading(false)
        } catch (error) {
            props.loading(false)
            dispatch({
                type: SHOW_COMMON_ERROR,
                commonError: error,
            } as IActionUI);
        }
    }
    const loadDataTask = async () => {
        const result = await props.commonActionFromDALV2(SettingDAL.getQuotaSupperAdmin, props.form.getFieldValue("organization"), "Task")

        try {
            const payload = await result
            if (payload.success) {
                setData2(payload.result)
            } else {
                notification.error({
                    message: BIGDATA_CONSTANT.message.default.title.error,
                    description: get(payload, "message", BIGDATA_CONSTANT.message.notification.fail)
                })
            }
        } catch (error) {
            dispatch({
                type: SHOW_COMMON_ERROR,
                commonError: error,
            } as IActionUI);
        }
    }

    const loadDataSubCategory = async () => {
        const result = await props.commonActionFromDALV2(SettingDAL.getSubCategory)
        try {
            const payload = await result
            if (payload.success) {
                setSubData(payload.results)
            } else {
                dispatch({
                    type: SHOW_COMMON_ERROR,
                    commonError: payload,
                } as IActionUI);
            }
        } catch (error) {
            dispatch({
                type: SHOW_COMMON_ERROR,
                commonError: error,
            } as IActionUI);
        }
    }

    const loadSuggestionOrg = async () => {
        setLoading(true)
        const result = await props.commonActionFromDALV2(SettingDAL.suggestionOrg, query)
        try {
            const response = await result
            if (response.success) {
                setLoading(false)
                setRes(response)
            } else {
                setLoading(false)
                setRes(new TagQuotaSetting())
            }
        } catch (error) {
            setLoading(false)
            setRes(new TagQuotaSetting())
        }
    }

    const handleUpdate = (type: string) => {
        type === TYPE_UPDATE.UPDATE_API_INGESTION ? setLoadingAPIIngestion(true) : setLoadingTask(true)

        props.loading(true)

        props.form.validateFields(async (error: any, values: any) => {
            if (error) {
                type === TYPE_UPDATE.UPDATE_API_INGESTION ? setLoadingAPIIngestion(false) : setLoadingTask(false)
                props.loading(false)
                return
            }
            let payloadValue = {}



            const valueConvertArrToObject = dataColumn.reduce((accumulator: any, value: string) => {
                if (value.indexOf("=") > -1) {
                    const valueAfterSplit = value.split("=")
                    const payloadValueNumber = () => {
                        if (props.form.getFieldValue("maxAllowed") >= +valueAfterSplit[1]) {
                            return +valueAfterSplit[1]
                        } else {
                            return props.form.getFieldValue("maxAllowed")
                        }

                    }
                    return { ...accumulator, [valueAfterSplit[0]]: payloadValueNumber() }
                }
                return {}
            }, {});


            if (type === TYPE_UPDATE.UPDATE_API_INGESTION) {
                payloadValue = {
                    alertMessage: props.form.getFieldValue("alertMessage1"),
                    category: "ApiIngestion",
                    enabledBlocked: values.enabledBlocked ? true : false,
                    quotaByKeys: {
                        _5minutely: props.form.getFieldValue("_5minutely"),
                        _hourly: props.form.getFieldValue("_hourly"),
                        _daily: props.form.getFieldValue("_daily"),
                    },
                    unblockedPeriodInMin: +props.form.getFieldValue("unblockedPeriodInMin")
                }
            } else {
                payloadValue = {
                    alertMessage: props.form.getFieldValue("alertMessage2"),
                    category: "Task",
                    maxAllowed: props.form.getFieldValue("maxAllowed") ? props.form.getFieldValue("maxAllowed") : 100,
                    quotaByKeys: valueConvertArrToObject,
                }
            }

            try {
                const result: Promise<any> = await props.commonActionFromDALV2(SettingDAL.updateQuotaLimit, props.form.getFieldValue("organization"), type === TYPE_UPDATE.UPDATE_API_INGESTION ? "ApiIngestion" : "Task", payloadValue)
                const payload = await result

                if (payload.success) {
                    type === TYPE_UPDATE.UPDATE_API_INGESTION ? setLoadingAPIIngestion(false) : setLoadingTask(false)
                    props.loading(false)
                    notification.success({
                        message: BIGDATA_CONSTANT.message.default.title.success,
                        description: BIGDATA_CONSTANT.message.notification.success.update,
                        icon: <Icon type="check-circle" style={{ color: getPrimaryColor(uiSetting) }} />
                    })
                } else {
                    type === TYPE_UPDATE.UPDATE_API_INGESTION ? setLoadingAPIIngestion(false) : setLoadingTask(false)
                    props.loading(false)
                    dispatch({
                        type: SHOW_COMMON_ERROR,
                        commonError: payload,
                    } as IActionUI);
                }
                props.form.validateFields((err: any, values: any) => {
                    const fields = Object.keys(values);
                    const fieldsExclueOrg = _.filter(fields, (item: string) => { return item != "organization" })
                    props.form.resetFields(fieldsExclueOrg)
                })

                setTimeout(() => {
                    loadFormByOrgId()
                }, 1000);
            } catch (error) {
                type === TYPE_UPDATE.UPDATE_API_INGESTION ? setLoadingAPIIngestion(false) : setLoadingTask(false)
                props.loading(false)
                dispatch({
                    type: SHOW_COMMON_ERROR,
                    commonError: error,
                } as IActionUI);
            }
        })
    }


    const renderDefaultColumn = () => {
        if (data2 && data2.quotaByKeys) {
            for (let p in data2.quotaByKeys) {
                dataColumn.push(p + '=' + data2.quotaByKeys[p])
            }
            return dataColumn;
        }
        return []
    }

    const addNewMappingField = () => {
        const value = update(dataColumn, {
            $push: [""]
        })
        setDataColumn(value)
    }


    const renderAddColumn = () => {
        return dataColumn.length && dataColumn.map((item: any, index: number) => {
            return (
                <SubCategogyItemForm
                    data={item}
                    lasItemLength={dataColumn.length}
                    indexColumn={index}
                    subData={subData}
                    form={props.form}

                    onAdd={() => {
                        addNewMappingField()
                    }}

                    onDelete={() => {
                        const dataColumnAfterChange = update(dataColumn, {
                            $splice: [[index, 1]]
                        })
                        setIsDeleteItem(true);
                        setDataColumn(dataColumnAfterChange)
                    }}

                    onChange={(values: any) => {
                        const data = `${values.sub || ""}=${values.number || ""}`
                        const dataMappingArrayAfterChange = update(dataColumn, {
                            [index]: {
                                $set: data
                            }
                        })
                        setDataColumn(dataMappingArrayAfterChange)
                    }}
                />
            )
        })
    }

    const onSearch = (e: string) => {
        setQuery(e)
    }

    const debounceSearch = React.useCallback(_.debounce(onSearch, 500), [])

    const organization = props.form.getFieldValue("organization")

    React.useEffect(() => {
        if (organization) {
            loadFormByOrgId()
        }
    }, [organization])

    React.useEffect(() => {
        if (!organization) {
            loadSuggestionOrg();
        }
    }, [organization])

    React.useEffect(() => {
        if (data2) {
            renderDefaultColumn()
            loadDataSubCategory()
        }
    }, [data2])

    React.useEffect(() => {
        if (query != undefined) {
            loadSuggestionOrg();
        }
    }, [query])

    React.useEffect(() => {
        if (isDeleteItem) {
            const subColumn = dataColumn.map((item: any, index: number) => {
                return `sub[${index}]`;
            });
            const numberColumn = dataColumn.map((item: any, index: number) => {
                return `number[${index}]`;
            });
            props.form.resetFields(subColumn.concat(numberColumn));
            setIsDeleteItem(false);
        }
    }, [isDeleteItem]);

    const loadFormByOrgId = () => {
        loadDataAPIIngestion()
        setDataColumn([])
    }

    return (
        <Form colon={true} hideRequiredMark={true}>
            <Row gutter={[5, 5]} type="flex">
                <Col xs={6}>
                    <Spin spinning={loading}>
                        <O2OFormItem label="Organization" >
                            {props.form.getFieldDecorator("organization", {
                                initialValue: props.id
                            })(
                                <Select 
                                    disabled={props.id ? true : false}
                                    filterOption={false}
                                    allowClear
                                    placeholder="Select one"
                                    showSearch
                                    showArrow
                                    onSearch={(e: string) => {
                                        debounceSearch(e)
                                    }}
                                    onSelect={() => {
                                        onSearch("")
                                    }}
                                >
                                    {res.results.map((item: any) => {
                                        return (
                                            <Select.Option key={item.id} value={item.id} >
                                                {item.name}
                                            </Select.Option>
                                        )
                                    })}
                                </Select>
                            )}
                        </O2OFormItem>
                    </Spin>
                </Col>


                {props.form.getFieldValue("organization") ? (
                    <Col xs={24}>
                        <Collapse defaultActiveKey={["ApiIngestion", "Task"]} style={{ marginTop: 10 }} expandIconPosition="right">
                            {/* ApiIngestion */}
                            <Collapse.Panel key="ApiIngestion" header="API Ingestion">
                                <Row gutter={[10, 10]} type="flex">
                                    <Col>
                                        <O2OFormItem label={
                                            <span>
                                                5 Minutely
                                                <Tooltip title="Total allowed calls within 5 minutes">
                                                    <Icon style={{ marginLeft: 5 }} type="question-circle" />
                                                </Tooltip>

                                            </span>
                                        }>
                                            {props.form.getFieldDecorator("_5minutely", {
                                                rules: [{ required: true, message: "Required" }],
                                                initialValue: data && data.quotaByKeys !== null ? data.quotaByKeys._5minutely : 3000
                                            })(
                                                <InputNumber
                                                    min={0}
                                                    placeholder='Please enter number'
                                                    formatter={(value: string) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    style={{ width: "100%" }}>
                                                </InputNumber>
                                            )}
                                        </O2OFormItem>
                                    </Col>
                                    <Col>
                                        <O2OFormItem label={
                                            <span>
                                                Hourly
                                                <Tooltip title="Total allowed calls within 1 hour">
                                                    <Icon style={{ marginLeft: 5 }} type="question-circle" />
                                                </Tooltip>

                                            </span>
                                        }>
                                            {props.form.getFieldDecorator("_hourly", {
                                                rules: [{ required: true, message: "Required" }],
                                                initialValue: data && data.quotaByKeys !== null ? data.quotaByKeys._hourly : 20000
                                            })(
                                                <InputNumber
                                                    min={0}
                                                    placeholder='Please enter number'
                                                    formatter={(value: string) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    style={{ width: "100%" }}>
                                                </InputNumber>
                                            )}
                                        </O2OFormItem>
                                    </Col>
                                    <Col>
                                        <O2OFormItem label={
                                            <span>
                                                Daily
                                                <Tooltip title="Total allowed calls within 1 day">
                                                    <Icon style={{ marginLeft: 5 }} type="question-circle" />
                                                </Tooltip>

                                            </span>
                                        }>
                                            {props.form.getFieldDecorator("_daily", {
                                                rules: [{ required: true, message: "Required" }],
                                                initialValue: data && data.quotaByKeys !== null ? data.quotaByKeys._daily : 150000,
                                            })(
                                                <InputNumber
                                                    min={0}
                                                    placeholder='Please enter number'
                                                    formatter={(value: string) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    style={{ width: "100%" }}>
                                                </InputNumber>
                                            )}
                                        </O2OFormItem>
                                    </Col>
                                    <Col>
                                        <O2OFormItem label={
                                            <span>
                                                Block
                                                <Tooltip title="Enable to block all API calls when reaching the limit quota">
                                                    <Icon style={{ marginLeft: 5 }} type="question-circle" />
                                                </Tooltip>

                                            </span>
                                        }>
                                            {props.form.getFieldDecorator("enabledBlocked", {
                                                initialValue: data ? (data.enabledBlocked === true ? true : false) : false,
                                                valuePropName: "checked",

                                            })(
                                                <Switch checkedChildren="Yes" unCheckedChildren="No" />
                                            )}
                                        </O2OFormItem>
                                    </Col>
                                    <Col>
                                        <O2OFormItem label="Error Message">
                                            {props.form.getFieldDecorator("alertMessage1", {
                                                rules: [{ required: true, message: "Required" }],
                                                initialValue: data ? data.alertMessage : "You have exceeded your rate limit."
                                            })(
                                                <Input placeholder='Please enter message'></Input>
                                            )}
                                        </O2OFormItem>
                                    </Col>
                                    <Col>
                                        <O2OFormItem label={
                                            <span>
                                                Unblock After
                                                <Tooltip title="Unblock the API calls since the last call">
                                                    <Icon style={{ marginLeft: 5 }} type="question-circle" />
                                                </Tooltip>

                                            </span>
                                        }>
                                            {props.form.getFieldDecorator("unblockedPeriodInMin", {
                                                rules: [{ required: true, message: "Required" }],
                                                initialValue: data ? data.unblockedPeriodInMin : 30
                                            })(
                                                <Select placeholder="Select one">
                                                    <Select.Option value={5}>5 minutes</Select.Option>
                                                    <Select.Option value={10}>10 minutes</Select.Option>
                                                    <Select.Option value={15}>15 minutes</Select.Option>
                                                    <Select.Option value={30}>30 minutes</Select.Option>
                                                    <Select.Option value={60}>60 minutes</Select.Option>
                                                    <Select.Option value={90}>90 minutes</Select.Option>
                                                    <Select.Option value={120}>120 minutes</Select.Option>
                                                </Select>
                                            )}
                                        </O2OFormItem>
                                    </Col>

                                    {!loading && blockObject ? (
                                        <Col xs={24}>
                                            <strong style={{ color: "red" }} >API Ingestion is blocked. Automatically unblock {moment(blockObject ? blockObject.unblockedAt : 0).fromNow()}.</strong> <Divider type='vertical' /> <Popconfirm title="Are you sure to unblock?" okText="Unblock" onConfirm={async () => {
                                                const orgId = props.form.getFieldValue("organization")
                                                setLoadingAPIIngestion(true)
                                                try {
                                                    const promise = await props.commonActionFromDALV2(CommonDAL.buildURL, `${BIGDATA_CONSTANT.url.svc}api/mngmnt/orgs/config/quota/${orgId}/ApiIngestion/unblocked`, {}, Methods.GET)
                                                    const data = await promise
                                                    setLoadingAPIIngestion(false)
                                                    if (data.success) {
                                                        notification.success({
                                                            message: BIGDATA_CONSTANT.message.notification.success.saved,
                                                            description: _.get(data, "message", "Unblocked successfully")
                                                        })
                                                        setTimeout(() => {
                                                            loadDataAPIIngestion()
                                                        }, 1000);
                                                    } else {
                                                        notification.error({
                                                            message: BIGDATA_CONSTANT.message.notification.fail.update,
                                                            description: _.get(data, "message", "Unblocked fail")
                                                        })
                                                    }
                                                } catch (error) {
                                                    setLoadingAPIIngestion(false)
                                                    notification.error({
                                                        message: BIGDATA_CONSTANT.message.notification.fail.update,
                                                        description: _.get(error, "message", "Unblocked fail")
                                                    })
                                                }

                                            }}>
                                                <Button type="link" size="small">Unblocked</Button>
                                            </Popconfirm>
                                        </Col>
                                    ) : null}

                                    <Col xs={24}>

                                        <Button loading={loadingAPIIngestion} type='primary' onClick={(e) => {
                                            handleUpdate(TYPE_UPDATE.UPDATE_API_INGESTION)
                                        }}>Update</Button>
                                    </Col>

                                </Row>
                            </Collapse.Panel>

                            {/* Task */}
                            <Collapse.Panel key="Task" header="Task">
                                <Row gutter={[10, 10]} type="flex">
                                    <Col>
                                        <O2OFormItem label={
                                            <span>
                                                Max Tasks
                                                <Tooltip title="Maximum of tasks are allowed (Min: 1, Max: 2,000)">
                                                    <Icon style={{ marginLeft: 5 }} type="question-circle" />
                                                </Tooltip>
                                            </span>
                                        }>
                                            {props.form.getFieldDecorator("maxAllowed", {
                                                initialValue: data2 ? data2.maxAllowed : 100,
                                                rules: [{ required: true, message: "Required" }],
                                            })(
                                                <InputNumber
                                                    placeholder='Please enter number'
                                                    min={1}
                                                    max={2000}
                                                    formatter={(value: string) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    style={{ width: "100%" }}
                                                >
                                                </InputNumber>
                                            )}
                                        </O2OFormItem>
                                    </Col>

                                    <Col xs={12}>
                                        <O2OFormItem label="Alert Messages">
                                            {props.form.getFieldDecorator("alertMessage2", {
                                                rules: [{ required: true, message: "Required" }],
                                                initialValue: _.get(data2, "alertMessage", "Reached the maximum of allowed tasks.")
                                            })(
                                                <Input placeholder='Please enter message'></Input>
                                            )}
                                        </O2OFormItem>
                                    </Col>

                                    <Col xs={24}>
                                        <O2OFormItem>
                                            <Row type='flex' gutter={[5, 5]} style={{ width: "100%", borderBottom: "1px solid #e8e8e8", margin: "10px 10px 10px 0" }} align='middle' justify='start'>
                                                <Col>
                                                    <Typography.Text strong>Quota by Category</Typography.Text>
                                                    <Divider type='vertical' />
                                                </Col>
                                                <Col>
                                                    <Button
                                                        type='link'
                                                        style={{ marginLeft: -20 }}
                                                        icon='plus-circle'
                                                        onClick={() => {
                                                            addNewMappingField()
                                                        }}>
                                                    </Button>
                                                </Col>

                                            </Row>
                                        </O2OFormItem>

                                        <O2OFormItem >
                                            {dataColumn && dataColumn.length > 0 ? renderAddColumn() : <Empty
                                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                                description={
                                                    <Button
                                                        icon='plus'
                                                        type='link'
                                                        onClick={() => {
                                                            addNewMappingField()
                                                        }}
                                                    >
                                                        Add column
                                                    </Button>
                                                }
                                            />
                                            }
                                        </O2OFormItem>
                                    </Col>


                                    <Col xs={24}>
                                        <Button loading={loadingTask} type='primary' onClick={() => {
                                            handleUpdate(TYPE_UPDATE.UPDATE_TASK)
                                        }}>Update</Button>
                                    </Col>

                                </Row>
                            </Collapse.Panel>
                        </Collapse>
                    </Col>
                ) : null}

            </Row>




        </Form>
    )
})

export const QuotaSettingComponent = ({ commonActionFromDALV2, id }: { commonActionFromDALV2: any, id?: string }) => {
    const [loading, setLoading] = React.useState(false)

    return <div>
        <Spin spinning={loading}>
            <QuotaSetting
                id={id}
                loading={(loading) => {
                    setLoading(loading)
                }}
                commonActionFromDALV2={commonActionFromDALV2}
            />
        </Spin>
    </div>
}
